// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-atrakcje-index-js": () => import("./../../../src/pages/atrakcje/index.js" /* webpackChunkName: "component---src-pages-atrakcje-index-js" */),
  "component---src-pages-galeria-checkbox-js": () => import("./../../../src/pages/galeria/checkbox.js" /* webpackChunkName: "component---src-pages-galeria-checkbox-js" */),
  "component---src-pages-galeria-checkbox-second-js": () => import("./../../../src/pages/galeria/checkbox-second.js" /* webpackChunkName: "component---src-pages-galeria-checkbox-second-js" */),
  "component---src-pages-galeria-index-js": () => import("./../../../src/pages/galeria/index.js" /* webpackChunkName: "component---src-pages-galeria-index-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-index-js": () => import("./../../../src/pages/kontakt/index.js" /* webpackChunkName: "component---src-pages-kontakt-index-js" */),
  "component---src-pages-o-nas-index-js": () => import("./../../../src/pages/o-nas/index.js" /* webpackChunkName: "component---src-pages-o-nas-index-js" */),
  "component---src-pages-oferta-index-js": () => import("./../../../src/pages/oferta/index.js" /* webpackChunkName: "component---src-pages-oferta-index-js" */),
  "component---src-pages-pokoje-index-js": () => import("./../../../src/pages/pokoje/index.js" /* webpackChunkName: "component---src-pages-pokoje-index-js" */),
  "component---src-pages-polityka-prywatnosci-index-js": () => import("./../../../src/pages/polityka-prywatnosci/index.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-index-js" */),
  "component---src-pages-polityka-prywatnosci-lista-dostawcow-index-js": () => import("./../../../src/pages/polityka-prywatnosci/lista-dostawcow/index.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-lista-dostawcow-index-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

